import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import ClientModel from "@/components/models/ClientModel";
import UserModel from "@/components/models/UserModel";
import ClientApi from "@/components/api/ClientApi";
import ProductModel from "@/components/models/ProductModel";
import ProductApi from "@/components/api/ProductApi";
@Module({ namespaced: true })
export default class Product extends VuexModule {
  @Action
  public async actionAddProduct(product: ProductModel) {
    const user: UserModel = await this.context.rootGetters["Auth/getUser"];
    const api = new ProductApi();
    product.user = user._id;
    product = await api.postProductItem(product);
    return product;
  }
}
