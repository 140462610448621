import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import CartApi from "@/components/api/CartApi";
import CartModel from "@/components/models/CartModel";
import UserModel from "@/components/models/UserModel";
@Module({ namespaced: true })
export default class Cart extends VuexModule {
  private _carts: Array<CartModel> = [];

  get carts(): Array<CartModel> {
    return this._carts;
  }

  @Mutation
  public async mutationSetCarts(list: Array<any>) {
    this._carts = list.reverse().map((item: any) => {
      const cart = new CartModel();
      Object.assign(cart, item);
      return cart;
    });
  }

  @Action
  public async actionAddCart(cart: CartModel) {
    const api = new CartApi();
    cart = await api.postCart(cart);
    return cart;
  }

  @Action
  public async actionShorten(url: string) {
    const api = new CartApi();
    return api.postShorten(url);
  }

  @Action
  public async actionUpdateCartLink(cart: CartModel) {
    const api = new CartApi();
    return api.putCartLink(cart);
  }

  @Action({ commit: "mutationSetCarts" })
  public async actionGetCarts() {
    const user = this.context.rootGetters["Auth/getUser"] as UserModel;
    const api = new CartApi();
    return api.getCarts(user);
  }

  @Action
  public async actionGetCart(id: string) {
    const api = new CartApi();
    const data = await api.getCart(id);
    const cart = new CartModel();
    Object.assign(cart, data);
    return cart;
  }
}
