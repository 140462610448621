import { Api } from "@/components/Api";
import axios from "axios";
import ProductModel from "@/components/models/ProductModel";

export default class ProductApi extends Api {

  public async searchVTEX(query: string) {
    const result = (await this.get(this.config.endpoint.get.search_vtex, {
    params: {
      produto: query,
    } 
    })) as any;
    return result.data.data;
  }

  public async getAutoComplete(query: string, brand: string) {
    const result = (await axios.get(this.config.endpoint.get.auto_complete, {
      params: {
        query: query,
        filter: '{!tag=product_brand}product_brand:"' + brand + '"'
      }
    })) as any;
    return result.data.placements[0].docs;
  }

  public async getOccProduct(id: string, color?: string) {
    const result = (await this.get(
      this.config.endpoint.get.occ_products + id
    )) as any;
    return result.data.data;
  }

  public async getVTEXProduct(id: string) {
    const result = (await this.get(
      this.config.endpoint.get.vtex_products + id
    )) as any;
    return result.data.data;
  }

  public async getVTEXProductRefId(id: string) {
    const result = (await this.get(
      this.config.endpoint.get.vtex_products_refId + id
    )) as any;
    return result.data.data;
  }

  public async postProductItem(product: ProductModel) {
    const result = (await this.post(
      this.config.endpoint.post.product,
      product
    )) as any;
    product._id = result.data.data._id;
    return product;
  }
}
