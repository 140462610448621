import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login/Login.vue";
import CartView from "../views/Cart/View/CartView.vue";
import CartCreate from "../views/Cart/Create/CartCreate.vue";
import Client from "../views/Client/Client.vue";
import User from "../views/User/User.vue";
import store from "../store";
import Dashboard from "@/views/Dashboard/Dashboard.vue";

Vue.use(VueRouter);

const ifNotAuthenticated = async (to: any, from: any, next: any) => {
  if (to.query && to.query.cpf) {
    await store.dispatch("Auth/actionAuthenticate", to.query.cpf).catch(error => {
      store.dispatch("Auth/actionLogout");
      next("/");
    });
  }
  const isAuthenticated = store.getters["Auth/isAuthenticated"];
  if (!isAuthenticated) {
    next("/");
  } else {
    next();
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Login,
    beforeEnter: (to: any, from: any, next: any) => {
      /**
       * Verifies if the user is authenticated
       * @type boolean
       */
      const isAuthenticated = store.getters["Auth/isAuthenticated"];
      if (isAuthenticated) {
        next("/cart/create");
      } else {
        next();
      }
    }
  },
  {
    path: "/cart",
    name: "CartView",
    component: CartView,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/cart/create",
    name: "CartCreate",
    component: CartCreate,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/cart/create/:id",
    name: "CartCreateFrom",
    component: CartCreate,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/client",
    name: "Client",
    component: Client,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/user/add",
    name: "User",
    component: User
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;