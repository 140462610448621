import { Component, Vue } from "vue-property-decorator";
import Structure from "@/views/Navigation/Structure/Structure.vue";
import { Action, Getter } from "vuex-class";
import CartModel from "@/components/models/CartModel";
@Component({
  components: {
    Structure
  }
})
export default class CartView extends Vue {
  @Getter("Cart/carts") carts!: Array<CartModel>;
  @Action("Cart/actionGetCarts") getCarts!: () => Array<CartModel>;

  private isLoading = false;

  async mounted() {
    this.isLoading = true;
    await this.getCarts();
    this.isLoading = false;
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }
}
