import Vue from "vue";
import { Component } from "vue-property-decorator";
import Structure from "@/views/Navigation/Structure/Structure.vue";
import { Action, Getter } from "vuex-class";
import CartModel from "@/components/models/CartModel";
@Component({
  components: {
    Structure
  }
})
export default class Dashboard extends Vue {
  @Getter("Cart/carts") carts!: Array<CartModel>;
  @Action("Cart/actionGetCarts") getCarts!: () => Array<CartModel>;

  private isLoading = false;

  get averageTicket(): number {
    let total = 0;
    let count = 0;
    if (this.carts.length === 0) {
      return 0;
    }
    this.carts.forEach((cart: CartModel) => {
      const date = new Date(cart.createdOn);
      const now = new Date();

      if (
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        total += cart.total;
        count++;
      }
    });
    return total / count;
  }

  get total(): number {
    let total = 0;
    this.carts.forEach((cart: CartModel) => {
      const date = new Date(cart.createdOn);
      const now = new Date();
      if (
        cart.status > 0 &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        total += cart.total;
      }
    });
    return total;
  }

  get totalMonth(): number {
    let total = 0;
    this.carts.forEach((cart: CartModel) => {
      const date = new Date(cart.createdOn);
      const now = new Date();
      if (
        cart.status > 0 &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear()
      ) {
        total += cart.total;
      }
    });
    return total;
  }

  async mounted() {
    this.isLoading = true;
    await this.getCarts();
    this.isLoading = false;
  }
}
