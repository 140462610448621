import ProductModel from "@/components/models/ProductModel";
import UserModel from "@/components/models/UserModel";

export default class CartModel {
  public _id!: string;
  public orderFormId!: string;
  public items!: Array<ProductModel>;
  public user!: UserModel;
  public status!: number;
  public coupon!: string;
  public link!: string;
  public lastChecked!: Date;
  public total!: number;
  public email!: string;
  public createdOn!: Date;
}
