export default class ProductModel {
  public sku!: string;
  public _id!: string;
  public productId!: string;
  public sellerId!: string;
  public productSignature!: boolean;
  public price!: number;
  public color!: string;
  public size!: string;
  public name!: string;
  public images!: Array<string>;
  public selected = false;
  public quantity!: number;
  public user!: string;
}
