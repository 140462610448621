




























import Vue from "vue";
import Loader from "@/views/Loader/Loader.vue";
export default Vue.extend({
  name: "App",
  components: { Loader },
  methods: {
    resize() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    refresh() {
      window.location.reload(true);
    }
  },
  mounted() {
    // this.resize();
    // window.addEventListener("resize", this.resize);
    this.$root.$on("error", (message: string) => {
      this.message = message;
      this.snackbar = true;
    });
  },

  created() {
    // @ts-ignore
    window["isUpdateAvailable"] = () => {
      this.updateAvailable = true;
    };
  },

  data: () => ({
    snackbar: false,
    message: "",
    updateAvailable: false
  })
});
