import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import DialogBase from "@/views/Dialog/DialogBase.vue";
import ProductApi from "@/components/api/ProductApi";
import ProductModel from "@/components/models/ProductModel";

@Component({
  components: {
    DialogBase
  },
})
export default class ProductAdd extends Vue {
  private modal = false;
  private query = "";
  private productApi = new ProductApi();
  private product: ProductModel = new ProductModel();
  private isLoading = false;
  private loadingProduct = false;
  private search = "";
  private entries = [];
  private productVTEX = null;
  private productOCC = null;
  private currentColor: any = {};
  private colorsList: Array<any> = [];
  private sizeList: Array<any> = [];
  private priceProduct = 0;
  private currentSKU: any = {};
  private quantity = 1;
  private searchCopy = "";
  private showError = false;
  private productHelperDialog = false;
  private selectedProductItem: any = null;
  private searchCategory = "adulto";
  private imagesList: Array<any> = [];

  private searchCategories = [
    {
      value: "adulto",
      label: "Reserva"
    },
    {
      value: "infantil",
      label: "Mini"
    },
    {
      value: "oficina",
      label: "Oficina"
    },
    {
      value: "eva",
      label: "Eva"
    }
  ];

  @Watch("searchCategory")
  saveToLocalStorage(value: string) {
    localStorage.setItem("searchCategory", value);
  }

  @Watch("productHelperDialog")
  onDialogHelperOpened(value: boolean) {
    if (value) {
      this.selectedProductItem = null;
    }
  }

  @Watch("search")
  handleQuery(value: string) {
    if (
      value &&
      value.length > 3 &&
      !value.match(/\d{6,}/) &&
      !value.match(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
      )
    ) {
      this.loadAutoComplete(value);
    } else {
      this.entries = [];
    }
  }

  selectProduct(option: any) {
    this.productHelperDialog = false;
    this.searchCopy = option.sku;
    this.getProduct();
  }

  async loadAutoComplete(query: string) {
    try {
      this.isLoading = true;
      this.entries = await this.productApi.searchVTEX(
        query
      );
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  get items() {
    const itemsEntries: Array<any> = [];
    this.entries.forEach((entry: any) => {
      itemsEntries.push({
        sku: entry.itemSkus[0].sku,
        name: entry.name,
        url: entry.link,
        images: entry.itemSkus[0].images
      });
    });
    return itemsEntries;
  }

  openProductHelper() {
    console.log("prepend helper");
    this.productHelperDialog = true;
  }

  reset() {
    this.isLoading = false;
    this.loadingProduct = false;
    this.currentColor = {};
    this.currentSKU = {};
    this.priceProduct = 0;
    this.productVTEX = null;
    this.productOCC = null;
    this.quantity = 1;
    this.searchCopy = "";
    this.imagesList = [];
    this.entries = [];
  }

  insertProduct() {
    const { name, productId }: any = this.productVTEX![0];
    this.$emit("insert", {
      sku: this.currentSKU.sku,
      productId: productId,
      price: this.priceProduct,
      color: this.currentColor.colorID,
      sellerId: this.currentSKU.sellerId,
      productSignature: this.currentSKU.productSignature,
      size: this.currentSKU.tamanho[0],
      name: name,
      images: this.getImagesByColor(),
      quantity: this.quantity
    });
    this.modal = false;
  }

  mounted() {
    this.$on("open", () => {
      this.modal = true;
      this.reset();
    });
    const searchCategory = localStorage.getItem("searchCategory");
    if (searchCategory) {
      this.searchCategory = searchCategory;
    }
  }

  priceFormatted(num: number) {
    return num.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  }

  get currentImage() {
    return this.getImagesByColor();
  }

  getImagesByColor(): Array<string> {
    let images = [];
    if (this.imagesList !== null) {
      images = this.imagesList.filter((item: string) =>
        item.match(this.currentColor.id + "_")
      );
    }
    return images;
  }

  selectOption(option: any) {
    this.query = option;
    this.search = option;
  }


  listImages(product: any): Array<string> {
    const images: Array<string> = [];
    product.data.skus.forEach((sku: any) => {
      images.push(sku.image);
    });
    return images;
  }

  listColors(product: any) {
    const colorsAvailable: Array<any> = [];
    product[0].itemSkus.forEach((sku: any) => {
      if (sku.images) this.imagesList.push(sku.images);
      if (
        !colorsAvailable.find(
          color => String(color.id) === String(sku.ID_COR_ORIGINAL)
        )
      ) {
        colorsAvailable.push({
          id: String(sku.ID_COR_ORIGINAL),
          hex: String(sku.VALOR_HEX_ORIGINAL),
          name: String(sku.DESC_COR_ORIGINAL),
          colorID: String(
            `${sku.ID_COR_ORIGINAL}|${sku.DESC_COR_ORIGINAL}|${sku.VALOR_HEX_ORIGINAL}`
          )
        });
      }
    });
    return colorsAvailable;
  }
  
  productPrice(product: any) {
    const salePrice = Number(product[0].itemSkus[0].preco[0].price);
    const listPrice = Number(product[0].itemSkus[0].preco[0].ListPrice);
    return salePrice !== listPrice ? salePrice : listPrice;
  }

  get listSize() {
    let sizesAvailable: Array<any> = [];
    if (this.productVTEX) {
      const product: any = this.productVTEX;
      product[0].itemSkus.forEach((sku: any) => {
        if (sku.tamanho !== null && sku.tamanho !== undefined) {
          if (
            !sizesAvailable.find(size => String(size.name) === sku.tamanho[0])
          ) {
            sizesAvailable.push({
              name: sku.tamanho[0],
              stock: false,
              sku: null
            });
          }
        }
      });
      sizesAvailable = sizesAvailable.map(size => {
        const sku = product[0].itemSkus.find(
          (sku: any) =>
            sku.tamanho[0] === size.name &&
            sku.ID_COR_ORIGINAL[0] === this.currentColor.id
        );
        if (sku) {
          size.sku = sku;
          size.stock = sku.preco[0].IsAvailable;
        }
        return size;
      });
    }

    sizesAvailable = sizesAvailable.filter(size => size.sku !== null);
    
    sizesAvailable.sort((itemA, itemB) => {
      const b = itemA.name;
      const a = itemB.name;
      if (parseInt(a) > 0) {
        return a > b ? -1 : 1;
      } else {
        if (a.charAt(0) === b.charAt(0)) {
          if (a.length > b.length) {
            return -1;
          } else if (a.length < b.length) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return a < b ? -1 : 1;
        }
      }
    });
    return sizesAvailable;
  }

  setColor(color: any) {
    this.currentColor = color;
    this.currentSKU = this.listSize[0].sku;
    this.quantity = 1;
  }

  setSku(size: any) {
    this.currentSKU = size.sku;
  }

  async getProduct() {
    const value = this.searchCopy;
    let productId = "";

     if(value.match("https://www.oficinareserva.com/")) {
        alert("Ainda não é possível procurar por produtos do Oficina Reserva, em breve estará disponível.");
        return;
      } 
      if (value.match(/https/) !== null) {
        if (value.match("kit")) {
          const regex = /(?=00)(\d+)| /g; 
          const result = regex.exec(value);
          if (result) {
            productId = "kit" + result[0];
          }
        } else {
          const regex = /(?=00)(\d+)| /g; 
          const result = regex.exec(value);
          if (result) {
            productId = result[0];
          }
        }
      } else {
        if (value.match(/\d+/) !== null) {
          productId = value;
        } else {
          alert("O termo informado não é uma URL, um ID ou SKU válido, tente inserir um valor válido ou tente usar o Buscar no site.");
          return;
        }
      }
      
    if (productId.length > 6) {
      try {
        this.loadingProduct = true;
        this.productVTEX = await this.productApi.getVTEXProductRefId(productId);
        this.loadingProduct = false;
        this.colorsList = this.listColors(this.productVTEX);
        this.priceProduct = this.productPrice(this.productVTEX);
        this.currentColor = this.colorsList[0];
      } catch (error) {
        this.reset();
        console.error(error);
        this.showError = true;
      }
    } else {
      try {
        this.loadingProduct = true;
        this.productVTEX = await this.productApi.getVTEXProduct(productId);
        this.loadingProduct = false;
        this.colorsList = this.listColors(this.productVTEX);
        this.priceProduct = this.productPrice(this.productVTEX);
        this.currentColor = this.colorsList[0];
      } catch (error) {
        this.reset();
        console.error(error);
        this.showError = true;
      }      
    }
  }
}
