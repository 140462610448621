export default class Configuration {
  private readonly _endpoint!: any;
  constructor() {
    this._endpoint = require("./endpoint/" + process.env.NODE_ENV + ".json");
  }

  get endpoint(): any {
    return this._endpoint;
  }
}
