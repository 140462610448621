import Vue from "vue";
import { Component } from "vue-property-decorator";
import Structure from "@/views/Navigation/Structure/Structure.vue";
import UserModel from "@/components/models/UserModel";
import { Action, Getter } from "vuex-class";
// import CartModel from "@/components/models/CartModel";
@Component({
  components: {
    Structure
  }
})
export default class User extends Vue {
  private user: UserModel = new UserModel();
  private isLoading = false;
  private message = "";
  private showMessage = false;
  @Action("Auth/actionAddUser") _addUser!: (user: UserModel) => UserModel;

  get rules(): Array<any> {
    return [(value: any) => !!value || "Preencha esse campo corretamente"];
  }

  isDisabled(): boolean {
    const form: any = this.$refs.form as any;
    return (form && !form.validate()) || !form;
  }

  async addUser() {
    try {
      this.isLoading = true;
      await this._addUser(this.user);
      this.isLoading = false;
    } catch (error) {
      this.message = "Usuário já existente";
      this.isLoading = false;
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    }
  }
}
