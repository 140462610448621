import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Auth";
import Client from "./modules/Client";
import Product from "./modules/Product";
import Cart from "./modules/Cart";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Auth: Auth,
    Client: Client,
    Product: Product,
    Cart: Cart
  },
  strict: debug
});
