import Vue from "vue";
import axios from "axios";
import { Component } from "vue-property-decorator";
import Structure from "@/views/Navigation/Structure/Structure.vue";
import ProductAdd from "@/views/Cart/Create/Dialog/ProductAdd.vue";
import CartCarousel from "@/views/Cart/Create/CartCarousel.vue";
import ClientModel from "@/components/models/ClientModel";

import { Action, Getter } from "vuex-class";
import UserModel from "@/components/models/UserModel";
import CartModel from "@/components/models/CartModel";
import ProductModel from "@/components/models/ProductModel";
import copy from "copy-to-clipboard";
import CartApi from "@/components/api/CartApi";

const enum STEPS {
  IDENTITY = 1,
  PRODUCTS = 2,
  CART = 3
}

//const HOST_BASE = "https://www.usereserva.com";

@Component({
  components: {
    Structure,
    ProductAdd,
    CartCarousel
  }
})
export default class CartCreate extends Vue {
  private client = new ClientModel();
  private email = "";
  private currentStep = STEPS.IDENTITY;
  private cart = new CartModel();
  private cartItems: Array<ProductModel> = [];
  private VTEXCartItems: Array<ProductModel> = [];
  private copySuccess = false;
  private isClientLoading = false;

  private cartUrl = "";
  private orderFormId = "";

  @Action("Client/actionCreateClient") createClient!: (
    email: string
  ) => Promise<any>;
  @Action("Product/actionAddProduct") addProductItem!: (
    product: ProductModel
  ) => ProductModel;
  @Action("Cart/actionAddCart") addCart!: (cart: CartModel) => CartModel;
  @Action("Cart/actionShorten") shorten!: (url: string) => string;
  @Action("Cart/actionGetCart") getCart!: (id: string) => CartModel;
  @Action("Cart/actionUpdateCartLink") updateCartLink!: (
    cart: CartModel
  ) => CartModel;
  @Action("Client/actionLastClientsFromUser") getLastClients!: () => Array<any>;
  @Getter("Auth/getUser") user!: UserModel;
  @Getter("Client/lastClients") lastClients!: Array<ClientModel>;

  get currentTitle(): string {
    switch (this.currentStep) {
      case STEPS.PRODUCTS:
        return "Produtos";
      case STEPS.CART:
        return "Carrinho";
      default:
        return "Identificação";
    }
  }

  get emailRules(): Array<any> {
    return [
      (value: any) => !!value || "Preencha esse campo corretamente",
      (value: any) =>
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null || "E-mail inválido"
    ];
  }

  get total(): number {
    let total = 0;
    this.cartItems.forEach((item: ProductModel) => {
      total += item.price * item.quantity;
    });
    return total;
  }

  get numCartItems(): number {
    let total = 0;
    this.cartItems.forEach((item: ProductModel) => {
      total += item.quantity;
    });
    return total;
  }

  async mounted() {
    this.isClientLoading = true;
    await this.getLastClients();
    this.isClientLoading = false;
    if (this.$route.params.id) {
      try {
        const cart = await this.getCart(this.$route.params.id);
        this.cartItems = cart.items.map((item: ProductModel) => {
          delete item._id;
          return item;
        });
        this.email = cart.email;
      } catch (error) {
        console.error(error);
      }
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email as string;
      setTimeout(() => {
        this.nextStep();
      }, 200);
    }
  }

  share() {
    if (navigator.share !== undefined && this.cartUrl.length > 0) {
      navigator.share({
        url: this.cartUrl
      });
    }
  }

  copyToClipboard() {
    copy(this.cartUrl);
    this.copySuccess = true;
  }

  noEmail() {
    this.email = "";
    this.nextStep();
  }

  prevStep() {
    this.currentStep = this.currentStep - 1;
  }

  addProduct(sku: any) {
    if (!this.cart.items) {
      this.cart.items = new Array<any>();
    }
    const product = new ProductModel();
    Object.assign(product, sku);
    this.cartItems.push(product);
  }

  removeSelected() {
    this.cartItems = this.cartItems.filter(
      (item: ProductModel) => !item.selected
    );
  }

  async nextStep() {
    switch (this.currentStep) {
      case STEPS.IDENTITY: {
        const element = this.$refs.identification as any;
        if (this.email.length > 0 && element.validate()) {
          try {
            this.$root.$emit("loader.show");
            this.client = await this.createClient(this.email);
          } catch (error) {
            this.$root.$emit("error", "Não foi possível criar o cliente");
            console.error(error);
          }
        } else {
          this.client = new ClientModel();
          this.client.user = this.user;
        }
        break;
      }
      case STEPS.PRODUCTS: {
        this.$root.$emit("loader.show");
        const cartItems = [] as any;
        const api = new CartApi();
        const promises: any = this.cartItems.map(async (item: ProductModel) => {
          const _data = await this.addProductItem(item);
          return _data;
        });
        await (async () => {
          this.cartItems = await Promise.all(promises);
        })();

        this.cartItems.forEach((item: any) => {
          cartItems.push({
            quantity: item.quantity,
            id: item.sku,
            seller: item.sellerId,
            productSignature: item.productSignature
          });
        });

        const data = {
          orderItems: cartItems,
          email: this.email,
          userId: this.user._id
        };
        const response = (await api.postCartVTEX(data)) as any;

        this.orderFormId = response.data.orderFormId;
        this.cartUrl = response.data.url;

        let cart = new CartModel();
        cart.user = this.user;
        cart.items = this.cartItems;
        cart.orderFormId = this.orderFormId;
        cart.status = 0;
        cart.coupon = this.user.coupon;
        cart.email = this.email;
        cart.total = this.total;
        cart = await this.addCart(cart);
        cart.link = this.cartUrl;
        await this.updateCartLink(cart);
        this.$root.$emit("loader.hide");
        break;
      }
      case STEPS.CART: {
        if (this.$route.params.id) {
          await this.$router.push("/cart/create");
        } else {
          this.cartItems = [];
          this.currentStep = 0;
          this.email = "";
        }
        break;
      }
    }
    this.currentStep = this.currentStep + 1;
    this.$root.$emit("loader.hide");
  }
}
