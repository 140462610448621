import { Api } from "@/components/Api";
import UserModel from "@/components/models/UserModel";

export default class AuthApi extends Api {  
  
  public async getUser(cpf: string) {
    const response = await this.get(this.config.endpoint.get.auth, {
      params: { cpf: cpf }
    });
    return response.data;
  }
  public async addUser(user: UserModel) {
    const response = (await this.post(
      this.config.endpoint.post.user,
      user
    )) as any;
    return response.data.data;
  }
}
