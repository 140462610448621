import Vue from "vue";
import { Component } from "vue-property-decorator";
import Structure from "@/views/Navigation/Structure/Structure.vue";
import { Action, Getter } from "vuex-class";
import CartModel from "@/components/models/CartModel";
@Component({
  components: {
    Structure
  }
})
export default class Client extends Vue {
  @Getter("Cart/carts") carts!: Array<CartModel>;
  @Action("Cart/actionGetCarts") getCarts!: () => Array<CartModel>;
  private isLoading = false;

  async mounted() {
    this.isLoading = true;
    await this.getCarts();
    this.isLoading = false;
  }

  get clients(): Array<any> {
    const clients: any[] = [];
    this.carts.forEach((item: CartModel) => {
      let client = clients.find((_client: any) => _client.email === item.email);
      if (client === undefined) {
        client = {
          email: item.email,
          carts: []
        };
        clients.push(client);
      }
      let total = 0;
      client.carts.push(item);
      client.carts.forEach((item: CartModel) => {
        if (item.status === 1) {
          total += item.total;
        }
      });
      client.total = total;
    });
    clients.sort((a: any, b: any) => {
      if (a.email > b.email) {
        return 1;
      } else if (a.email < b.email) {
        return -1;
      } else {
        return 0;
      }
    });
    return clients;
  }
}
