import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import ClientModel from "@/components/models/ClientModel";
import UserModel from "@/components/models/UserModel";
import ClientApi from "@/components/api/ClientApi";
@Module({ namespaced: true })
export default class Client extends VuexModule {
  private client!: ClientModel;
  private _lastClients: Array<ClientModel> = [];

  get lastClients(): Array<ClientModel> {
    return this._lastClients;
  }

  @Mutation
  public mutationSetLastClients(arr: Array<any>) {
    this._lastClients = arr.reverse().map(item => {
      const obj = new ClientModel();
      Object.assign(obj, item);
      return obj;
    });
  }

  @Action
  public async actionCreateClient(email: string) {
    const user: UserModel = this.context.rootGetters["Auth/getUser"];
    const api = new ClientApi();
    const client = new ClientModel();
    const data = (await api.postClient(email, user._id)) as any;
    Object.assign(client, data.data);
    client.user = user;
    return client;
  }

  @Action({ commit: "mutationSetLastClients" })
  public async actionLastClientsFromUser() {
    this.context.commit("mutationSetLastClients", []);
    const api = new ClientApi();
    const user: UserModel = this.context.rootGetters["Auth/getUser"];
    const clients = (await api.getClientsByUser(user._id)) as any;
    return clients.data;
  }
}
