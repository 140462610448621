















import DialogBase from "./DialogBase";
export default DialogBase;
