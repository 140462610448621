
















import Vue from "vue";
export enum LOADER {
  SHOW = "loader.show",
  HIDE = "loader.hide"
}
export default Vue.extend({
  name: "Loader",
  mounted() {
    this.$root.$on(LOADER.SHOW, () => {
      this.loader = true;
    });
    this.$root.$on(LOADER.HIDE, () => {
      this.loader = false;
    });
  },
  data: () => ({
    loader: false
  })
});
