

























































import User from "./User";

export default User;
