




































































import AppMenu from "@/views/Navigation/Menu/AppMenu";
export default AppMenu;
