<template>
  <div class="carousel d-flex">
    <img :src="images[0]" height="140" alt="image" />
  </div>
</template>
<style lang="scss">
.carousel {
  > div {
    margin-right: 10px;
  }
}
</style>
<script>
export default {
  props: {
    images: {
      type: Array
    }
  },
  name: "CartCarousel"
};
</script>
