import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import UserModel from "@/components/models/UserModel";

@Component
export default class AppMenu extends Vue {
  private flag = false;
  @Action("Auth/actionLogout") logout!: () => void;
  @Getter("Auth/getUser") user!: UserModel;

  created() {
    this.$on("open", () => {
      this.flag = true;
    });
    this.$on("close", () => {
      this.flag = true;
    });
  }

  async doLogout(): Promise<any> {
    await this.logout();
    await this.$router.push("/");
  }
}
