import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Configuration from "@/config/Configuration";

const API_TOKEN =
    "$2y$12$8.zpei6EMI3I54ev8k0UqeMRgq5xu/mBWhZj6Nr0/Qg/u0hz3YBPG";

/**
 * ES6 Axios Class.
 *
 * @class Api
 * @extends {Axios}
 * @example
 * class UserApi extends Api {
 *   public constructor (config) {
 *     super(config);
 *
 *     this.login=this.login.bind(this);
 *   }
 *
 *   public login (user: User) {
 *     return this.api.post<string, User, AxiosResponse<User>>("https://www.domain/login", {name: user.name, pass: user.pass})
 *        .then((res: AxiosResponse<string>) => res.data);
 *   }
 * }
 */
export class ApiError extends Error {
  public status = 0;
}

export class Api {
  [x: string]: any;

  protected config!: Configuration;
  protected static token: string;
  public static $root: any;
  public static ERROR = "API::ERROR";

  /**
   * Creates an instance of Api.
   *
   * @param {import("axios").AxiosRequestConfig} [config] - axios configuration.
   * @memberof Api
   */
  public constructor(config?: AxiosRequestConfig) {
    this.api = axios.create(config);
    // const updateInterceptors = () => {
    //   axios.interceptors.request.use((param: AxiosRequestConfig) => {
    //     console.log(Api.token, param)
    //     if (Api.token && param.url && !param.url.match(/login/)) {
    //       console.log('foi')
    //       param.headers['Authorization'] = "Bearer " + Api.token;
    //     }
    //     //
    //     // console.log(param)
    //     return param;
    //   });
    // };
    // updateInterceptors();
    this.api.interceptors.response.use(
        (response: any) => {
          return response;
        },
        async (error: any) => {
          if (error.response && error.response.status === 401) {
            const config = error.config;
            const token = await this.getToken();
            config.headers["Authorization"] = `Bearer ${token}`;
            return new Promise((resolve, reject) => {
              axios
                  .request(config)
                  .then(response => {
                    resolve(response);
                  })
                  .catch(error => {
                    reject(error);
                  });
            });
          } else {
            const _error: ApiError = new Error(error) as ApiError;
            if (Api.$root) {
              Api.$root.$emit(Api.ERROR, _error);
            }
            return Promise.reject(error);
          }
        }
    );
    this.config = new Configuration();
    this.getUri = this.getUri.bind(this);
    this.request = this.request.bind(this);
    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.head = this.head.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.patch = this.patch.bind(this);
  }

  public async getToken() {
    const response = await axios.post(this.config.endpoint.post.token, null, {
      headers: {
        Authorization: "Bearer " + API_TOKEN
      }
    });
    Api.token = response.data.access_token;
    localStorage.setItem("access_token", Api.token);
    return Api.token;
  }

  public setAuthorizationHeader(
      config?: AxiosRequestConfig
  ): AxiosRequestConfig {
    if (!config) {
      config = {
        headers: {}
      };
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers["Authorization"] = "Bearer " + Api.token;
    return config;
  }

  /**
   * Get Uri
   *
   * @param {import("axios").AxiosRequestConfig} [config]
   * @returns {string}
   * @memberof Api
   */
  public getUri(config?: AxiosRequestConfig): string {
    return this.api.api.getUri(config);
  }

  /**
   * Generic request.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP axios response payload.
   * @memberof Api
   *
   * @example
   * api.request({
   *   method: "GET|POST|DELETE|PUT|PATCH"
   *   baseUrl: "http://www.domain.com",
   *   url: "/api/v1/users",
   *   headers: {
   *     "Content-Type": "application/json"
   *  }
   * }).then((response: AxiosResponse<User>) => response.data)
   *
   */
  public request<T, R = AxiosResponse<T>>(
      config: AxiosRequestConfig
  ): Promise<R> {
    return this.api.api.request(config);
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   * @memberof Api
   */
  public get<T, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.get(url, config);
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public delete<T, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.delete(url, config);
  }

  /**
   * HTTP HEAD method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public head<T, R = AxiosResponse<T>>(
      url: string,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.head(url, config);
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public post<T, B, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.post(url, data, config);
  }

  /**
   * HTTP PUT method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public put<T, B, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.put(url, data, config);
  }

  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public patch<T, B, R = AxiosResponse<T>>(
      url: string,
      data?: B,
      config?: AxiosRequestConfig
  ): Promise<R> {
    config = this.setAuthorizationHeader(config);
    return this.api.patch(url, data, config);
  }

  /**
   *
   * @template T - type.
   * @param {import("axios").AxiosResponse<T>} response - axios response.
   * @returns {T} - expected object.
   * @memberof Api
   */
  public success<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  public error(error: AxiosError<Error>) {
    throw error;
  }
}
