import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import UserModel from "@/components/models/UserModel";
import AuthApi from "@/components/api/AuthApi";

const LOCAL_STORAGE_KEY = "RON_AUTH";
const HOURS_VALIDATE = 24 * 30;

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  private user!: UserModel;

  constructor(...args: any) {
    super(args);
    const obj = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string);
    this.user = new UserModel();
    if (obj) {
      Object.assign(this.user, obj);
    }
  }

  get isAuthenticated(): boolean {
    const now = new Date();
    now.setHours(now.getHours() - HOURS_VALIDATE);
    return (
        this.user._id !== null &&
        this.user._id !== undefined &&
        this.user._id.length > 5 &&
        now < new Date(this.user.lastLogin)
    );
  }

  get getUser(): UserModel{
    return this.user;
  }

  @Mutation
  public mutationRemoveUser() {
    this.user = new UserModel();
  }

  @Mutation
  public mutationSetUser(user: any) {
    this.user = new UserModel();
    Object.assign(this.user, user);
    this.user.lastLogin = new Date();
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.user));
  }

  @Action
  public async actionAuthenticate(cpf: string) {
    const api = new AuthApi();
    try {
      const response = (await api.getUser(cpf)) as any;
      const data = response.data as any;
      this.context.commit("mutationSetUser", data);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  @Action({ commit: "mutationRemoveUser" })
  public async actionLogout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem("access_token");
  }

  @Action
  public async actionAddUser(user: UserModel) {
    const api = new AuthApi();
    try {
      const data = await api.addUser(user);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
