import { Api } from "@/components/Api";
import ProductModel from "@/components/models/ProductModel";
import CartModel from "@/components/models/CartModel";
import UserModel from "@/components/models/UserModel";

export default class CartApi extends Api {
  public async postCartVTEX(data: any) {
    const response = (await this.post(
      this.config.endpoint.post.cart_vtex,
      data
    )) as any;
    return response;
  }

  public async postCart(cart: CartModel) {
    const payload: any = {
      user: cart.user._id,
      status: cart.status,
      orderFormId: cart.orderFormId,
      coupon: cart.coupon,
      lastChecked: new Date(),
      total: cart.total,
      link: "",
      email: cart.email,
      items: cart.items.map((item: ProductModel) => {
        return item._id;
      })
    };
    const result = (await this.post(
      this.config.endpoint.post.cart,
      payload
    )) as any;
    cart._id = result.data.data._id;
    return cart;
  }

  public async postShorten(url: string) {
    const result = (await this.post(this.config.endpoint.post.shorten, {
      url: url
    })) as any;
    return result.data.data.url;
  }

  public async putCartLink(cart: CartModel) {
    const result = (await this.put(
      this.config.endpoint.put.cart + "/" + cart._id,
      {
        link: cart.link
      }
    )) as any;
    return result.data.data;
  }

  public async getCarts(user: UserModel) {
    const result = (await this.get(this.config.endpoint.get.carts, {
      params: {
        user: user._id
      }
    })) as any;
    return result.data.data;
  }

  public async getCart(id: string) {
    const result = (await this.get(
      this.config.endpoint.get.cart + "/" + id
    )) as any;
    return result.data.data;
  }
}
