import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
@Component
export default class Login extends Vue {
  private cpf = "";

  @Action("Auth/actionAuthenticate") authenticate!: (
      cpf: string
  ) => Promise<any>;

  async mounted() {
    if (this.$route.query.cpf && this.$route.query.cpf.length > 0) {
      this.cpf = this.$route.query.cpf as string;
      await this.doAuth();
    }
  }

  async doAuth() {
    this.$root.$emit("loader.show");
    try {
      await this.authenticate(this.cpf);
      await this.$router.push("/cart/create");
    } catch (error) {
      console.error(error);
      this.$root.$emit("error", "Usuário " + this.cpf + " não cadastrado.");
      this.cpf = "";
    }
    this.$root.$emit("loader.hide");
  }
}
