import { Api } from "@/components/Api";

export default class ClientApi extends Api {
  public async postClient(email: string, userId: string) {
    const response = await this.post(this.config.endpoint.post.client, {
      user: userId,
      email: email
    });
    return response.data;
  }

  public async getClientsByUser(user: string) {
    const response = await this.get(this.config.endpoint.get.clients, {
      params: {
        user: user
      }
    });
    return response.data;
  }
}
