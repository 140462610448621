













import Structure from "@/views/Navigation/Structure/Structure";
export default Structure;
