import { Component, Vue, Prop } from "vue-property-decorator";
import AppBar from "@/views/Navigation/AppBar/AppBar.vue";
import AppMenu from "@/views/Navigation/Menu/AppMenu.vue";
// import { Action } from "vuex-class";
@Component({
  components: {
    AppBar,
    AppMenu
  }
})
export default class Structure extends Vue {
  @Prop() readonly title!: string;
}
